import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const UserdashbordSliderSection = () => {
    // Slider settings
    const settings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    // Example slider content (you can replace it with dynamic data)
    const sliderContent = [
        { id: 1, image: 'https://via.placeholder.com/600x300?text=Slide+1', caption: 'Slide 1' },
        { id: 2, image: 'https://via.placeholder.com/600x300?text=Slide+2', caption: 'Slide 2' },
        { id: 3, image: 'https://via.placeholder.com/600x300?text=Slide+3', caption: 'Slide 3' },
    ];

    return (
        <div className='slider-section 2xl:max-w-[600px] max-w-[380px] m-auto'>
            <Slider {...settings}>
                {sliderContent.map((slide) => (
                    <div key={slide.id} className='slick-slide rounded-lg'>
                        <div className='h-[150px] bg-gray-400  rounded-lg'>

                            <p className='text-center mt-2'>{slide.caption}</p>
                        </div>
                        {/* <img src={slide.image} alt={slide.caption} className='w-full h-auto' /> */}
                    </div>
                ))}
            </Slider>

            <style jsx>{`
                .slick-dots li button:before {
                    color: red; /* Inactive dots color */
                }

                .slick-dots li.slick-active button:before {
                    color: red; /* Active dot color */
                }
            `}</style>
        </div>
    );
};
