import React, { useState, useEffect } from 'react';
import { section1bg } from '../../images/Images';
import { AdminSidebar } from '../AdminSidebar/AdminSidebar';
import { addQuestionCard, deleteQuestionCard, getquestionCard } from '../../components/Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AddQuestionCard = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [questionNumber, setQuestionNumber] = useState('');
    const [questionPrice, setQuestionPrice] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [questioncardData, setQuestioncardData] = useState([]);
    const token = localStorage.getItem('admintoken');

    // Fetch question card data
    const getQuestionCardData = async (token) => {
        try {
            const response = await getquestionCard(token);
            setQuestioncardData(response.data.data);
        } catch (error) {
            console.error('Error fetching question card data:', error);
        }
    };

    useEffect(() => {
        if (token) {
            getQuestionCardData(token);
        } else {
            console.log('No token found in local storage');
        }
    }, [token]);

    const openModal = () => {
        setIsModalOpen(true);
        setErrorMessage('');
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setQuestionNumber('');
        setQuestionPrice('');
        setErrorMessage('');
    };

    // Handle adding a new card
    const handleAddCard = async () => {
        if (!questionNumber || !questionPrice || questionNumber <= 0 || questionPrice <= 0) {
            setErrorMessage('Both fields are required and must be positive numbers.');
            return;
        }

        try {
            const response = await addQuestionCard(questionNumber, questionPrice, token); 
        
            if (response.data.message === "Question card added successfully") {
                toast.success(response.data.message);
                getQuestionCardData(token); 
            } else {
                toast.error(response.data.message || 'Failed to add the question card.');
            }
        } catch (error) {
            toast.error('Failed to add the question card.');
        }

        closeModal();
    };

    const handleDelete = async (id) => {
        if (!token) {
            toast.error('No admin token found.');
            return;
        }

        try {
            const response = await deleteQuestionCard(id, token);
         
            if (response.data.message === "Question Card deleted successfully") {
                toast.success('Question card deleted successfully.');
                getQuestionCardData(token); 
            } else {
                toast.error(response.message || 'Failed to delete the question card.');
            }
        } catch (error) {
            toast.error('An error occurred while deleting the question card.');
        }
    };

    return (
        <div className="flex">
            <AdminSidebar />
            <div
                style={{
                    backgroundImage: `url(${section1bg})`,
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                    overflow: 'hidden',
                    backgroundPosition: 'center',
                }}
                className="min-h-screen w-full px-8 py-6 relative md:w-[80%] md:ml-[20%]"
            >
                <div className="absolute inset-0 bg-black opacity-60" />
                <div className="relative z-[99]">
                    <div className="border-b-[1px] border-white w-full py-[13px] mb-6">
                        <h1 className="text-white font-inter font-bold text-2xl">Add Question Card</h1>
                    </div>

                    <div className="text-right mb-6">
                        <button
                            className="bg-custom-red text-white rounded-lg py-[10px] px-[40px] font-mono"
                            onClick={openModal}
                        >
                            Add Card
                        </button>
                    </div>

                    <div className="container mx-auto p-4 rounded-lg">
                        <table className="min-w-full bg-white rounded-lg">
                            <thead>
                                <tr>
                                    <th className="py-2 border">Sr.No</th>
                                    <th className="py-2 border">Questions</th>
                                    <th className="py-2 border">Price</th>
                                    <th className="py-2 border">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {questioncardData.length > 0 ? (
                                    questioncardData.map((card, index) => (
                                        <tr key={card._id}>
                                            <td className="py-2 border text-center">{index + 1}</td>
                                            <td className="py-2 border text-center">{card.Questions}</td>
                                            <td className="py-2 border text-center">{card.Price}</td>
                                            <td className="py-2 border text-center">
                                                <button
                                                    className="bg-red-500 text-white px-4 py-2 rounded"
                                                    onClick={() => handleDelete(card._id)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4" className="py-2 text-center">
                                            <p>No data available</p>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {isModalOpen && (
                        <div className="fixed inset-0 flex items-center justify-center z-[100] bg-black bg-opacity-50">
                            <div className="bg-white p-6 rounded-lg w-[90%] md:w-[400px] shadow-lg relative">
                                <h2 className="text-2xl font-bold mb-4 text-center">Add New Card</h2>

                                {errorMessage && (
                                    <div className="mb-4 text-red-500 text-center">{errorMessage}</div>
                                )}

                                <div className="mb-4">
                                    <label className="block text-gray-700 font-bold mb-2">
                                        How Many Questions
                                    </label>
                                    <input
                                        type="number"
                                        value={questionNumber}
                                        onChange={(e) => setQuestionNumber(e.target.value)}
                                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-custom-red"
                                        placeholder="Enter number of questions"
                                    />
                                </div>

                                <div className="mb-4">
                                    <label className="block text-gray-700 font-bold mb-2">
                                        Question Price
                                    </label>
                                    <input
                                        type="number"
                                        value={questionPrice}
                                        onChange={(e) => setQuestionPrice(e.target.value)}
                                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-custom-red"
                                        placeholder="Enter question price"
                                    />
                                </div>

                                <div className="flex justify-end space-x-4">
                                    <button
                                        className="bg-gray-500 text-white px-4 py-2 rounded-lg"
                                        onClick={closeModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-custom-red text-white px-4 py-2 rounded-lg"
                                        onClick={handleAddCard}
                                    >
                                        Add Card
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};
