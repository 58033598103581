import React, { useState, useEffect } from 'react';
import UserDashbordNavbar from '../UserDashbordNavbar/UserDashbordNavbar';
import UserDashbordBottomBar from '../UserDashbordBottomBar/UserDashbordBottomBar';
import { fetchPaymenthistoryData, fetchUserInfo } from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { section1bg } from '../../images/Images';


export const AddPayment = () => {
    const [activeTab, setActiveTab] = useState('walletBalance');
    const [amount, setAmount] = useState('');
    const [selectedAmount, setSelectedAmount] = useState(null);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(7);
    const [userName, setUserName] = useState('');
    const [userNumber, setUserNumber] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const amounts = [200, 400, 800, 1000, 1500, 2000];


    const getPaymentHistory = async (token) => {
        try {
            const data = await fetchPaymenthistoryData(token);
            setPaymentHistory(data.data.data);
        } catch (error) {
            console.error('Error fetching payment history:', error);
            toast.error("Failed to fetch payment history.");
        }
    };

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        if (tabName === 'transactionHistory') {
            const token = localStorage.getItem('usertoken');
            if (token) {
                getPaymentHistory(token);
            } else {
                toast.error("Please log in to see payment history.");
            }
        }
    };

    const handleCardClick = (value) => {
        setAmount(value);
        setSelectedAmount(value);
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
        setSelectedAmount(null);
    };

    const getUserInfo = async (token) => {
        try {
            const data = await fetchUserInfo(token);
            setUserName(data.data.data.firstName + " " + data.data.data.lastName);
            setUserNumber(data.data.data.number);
            setUserEmail(data.data.data.email);
        } catch (error) {
            console.error('Error fetching user info:', error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('usertoken');
        if (token) {
            getUserInfo(token);
        } else {
            console.log('No token found in local storage');
        }
    }, []);

    const totalPages = Math.ceil(paymentHistory.length / itemsPerPage);

    const currentTransactions = paymentHistory.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const renderPagination = () => {
        if (totalPages <= 1) return null;

        const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

        return (
            <div className="flex justify-center mt-4">
                <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="px-3 py-1 mx-1 bg-gray-800 text-white rounded-lg"
                >
                    Previous
                </button>
                {pageNumbers.map((page) => (
                    <button
                        key={page}
                        onClick={() => setCurrentPage(page)}
                        className={`px-3 py-1 mx-1 rounded-lg ${currentPage === page ? 'bg-red-500 text-white' : 'bg-gray-800 text-white'}`}
                    >
                        {page}
                    </button>
                ))}
                <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="px-3 py-1 mx-1 bg-gray-800 text-white rounded-lg"
                >
                    Next
                </button>
            </div>
        );
    };

    // Redirect with POST function
    const initiateQrPayment = (e) => {
        e.preventDefault();

        const postData = {
            amount: selectedAmount || amount,
            name: userName,
            mobile: userNumber,
            email: userEmail,
            pay_for: 'freecharge',
            redirect_url: 'http://neelimabansaltarotreader.in/paymentsucess',
        };

        const form = document.createElement('form');
        form.method = 'POST';
        form.action = 'https://digitalfusion.in/Home/initQrPayment';

        for (const key in postData) {
            if (postData.hasOwnProperty(key)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = postData[key];
                form.appendChild(input);
            }
        }

        document.body.appendChild(form);
        form.submit();
    };

    return (
        <div style={{
            backgroundImage: `url(${section1bg})`,
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundRepeat: "no-repeat",
            backgroundPosition: 'center',
        }}>
            <div className="absolute inset-0 bg-black opacity-80" />
            <div className='2xl:max-w-[600px] max-w-[390px] border-[1px] border-white m-auto min-h-[100vh] pb-[100px]'>
                <UserDashbordNavbar />
                <div className='relative z-[99] pt-[100px] px-[20px]'>
                    <h1 className='font-inter font-semibold text-[18px] text-center text-white'>PAYMENT</h1>

                    <div className="flex justify-around mt-4 mb-4">
                        <button
                            className={`px-4 py-2 text-white ${activeTab === 'walletBalance' ? 'border-b-2 border-red-500' : ''}`}
                            onClick={() => handleTabClick('walletBalance')}>
                            Wallet Balance
                        </button>
                        <button
                            className={`px-4 py-2 text-white ${activeTab === 'transactionHistory' ? 'border-b-2 border-red-500' : ''}`}
                            onClick={() => handleTabClick('transactionHistory')}>
                            Transaction History
                        </button>
                    </div>

                    {activeTab === 'walletBalance' && (
                        <div>
                            <input
                                type="number"
                                value={amount}
                                onChange={handleAmountChange}
                                placeholder="Enter amount"
                                className="w-full px-4 py-2 mb-4 text-black outline-none rounded-lg"
                            />
                            <div className="grid grid-cols-3 gap-2 mb-4">
                                {amounts.map((amt, index) => (
                                    <div
                                        key={index}
                                        className={`border p-2 cursor-pointer rounded-lg ${selectedAmount === amt ? 'bg-red-500 text-white' : 'bg-white'}`}
                                        onClick={() => handleCardClick(amt)}
                                    >
                                        {amt}
                                    </div>
                                ))}
                            </div>
                            <button
                                onClick={initiateQrPayment}
                                className="w-full py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                            >
                                Initiate Payment
                            </button>
                        </div>
                    )}

                    {activeTab === 'transactionHistory' && (
                        <div>
                            {currentTransactions.length > 0 ? (
                                <ul className="space-y-2">
                                    {currentTransactions.map((transaction, index) => (
                                        <li key={transaction._id} className="bg-white p-2 rounded-lg shadow">
                                            <div className='flex justify-between'>
                                                <div className='flex flex-col gap-[2px]'>
                                                    <strong>Transaction Id</strong>
                                                    <p>{transaction._id}</p>
                                                </div>

                                                <div className='flex flex-col gap-[2px]'>
                                                    <strong>Amount</strong>
                                                    <p><strong>₹{transaction.amount}</strong></p>
                                                </div>
                                            </div>
                                            <div><strong>Date:</strong> {new Date(transaction.createdAt).toLocaleString()}</div>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="text-white">No transactions found.</p>
                            )}
                            {renderPagination()}
                        </div>
                    )}

                </div>
                <UserDashbordBottomBar />
                <ToastContainer />
            </div>
        </div>
    );
};

export default AddPayment;
