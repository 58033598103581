import React, { useState, useEffect } from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { logo, walleticon } from '../../images/Images';
import { fetchPaymenthistoryData } from '../Api/Api';

const UserDashbordNavbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [TotalAmount, setTotalAmount] = useState(0);
    // Dynamic menu items
    const menuItems = [
        { name: 'Dashboard', link: '/dashboard' },
        { name: 'Settings', link: '/settings' },
        { name: 'Profile', link: '/profile' },
        { name: 'Logout', link: '/logout' },  // You can add more menu items here
    ];

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState);
    };

    const getPaymentHistory = async (token) => {
        try {
            const data = await fetchPaymenthistoryData(token);
            setTotalAmount(data.data.totalAmount);
        } catch (error) {
            console.error('Error fetching payment history:', error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('usertoken');
        if (token) {
            getPaymentHistory(token);
        } else {
            console.log('No token found in local storage');
        }
    }, []);

    return (
        <div className='sticky top-0 z-50 2xl:max-w-[600px] max-w-[390px] m-auto'>
            <div className='px-[20px] '>
                <div className='flex flex-row justify-between items-center'>
                    <div className='flex flex-row items-center gap-[20px]'>
                        {/* <button onClick={toggleDrawer} className='text-white text-[30px]'>
                            <i className='fa fa-bars' aria-hidden='true'></i>
                        </button> */}
                        <a href='/userdashbord'>
                            <img src={logo} className='w-[150px]' alt='logo' />
                        </a>
                    </div>
                    <div className='bg-[#9b181557]  rounded-[10px] py-[8px] px-[5px]'>
                        <p className='flex flex-row gap-[5px] items-center '>
                            <span><img src={walleticon} alt='wallet' className='w-[20px]' /> </span>
                            <span>₹{TotalAmount || 0}</span>
                        </p>
                    </div>
                </div>
            </div>

            {/* Drawer positioned outside the restricted container */}
            <Drawer open={isOpen} onClose={toggleDrawer} direction='left' className="w-full overflow-hidden">
                <div className='p-[20px] bg-black h-full'>
                    {/* Drawer content */}
                    <div className='text-white'>
                        <h2>Menu</h2>
                        <ul className='mt-4'>
                            {menuItems.map((item, index) => (
                                <li key={index} className='mb-2'>
                                    <a href={item.link}>{item.name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default UserDashbordNavbar;
