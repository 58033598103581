import React, { useEffect, useState } from 'react';
import { section1bg } from '../../images/Images';
import { addPayment } from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const PaymentSuccessResponse = () => {
    const [paymentData, setPaymentData] = useState(null);
    const [amount, setAmount] = useState('');
    const [utrno, setUtrno] = useState('');
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('usertoken');
    const navigate = useNavigate();

    const getPaymentDataFromUrl = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const encodedData = queryParams.get('data');
        if (encodedData) {
            try {
                const decodedData = JSON.parse(decodeURIComponent(encodedData));
                return decodedData;
            } catch (error) {
                console.error('Error decoding payment data:', error);
            }
        }
        return null;
    };

    const addpaymentFn = async () => {
        try {
            const response = await addPayment(token, amount, utrno);
            console.log('Payment added successfully:', response);
            if (response.data.message === "Payment added successfully") {
                toast.success("Payment was successful!");
                setTimeout(() => {
                    navigate('/addpayment');
                }, 2000);
            } else {
                toast.error("Payment failed.");
            }
        } catch (error) {
            console.error('Error during Payment:', error); // Use the correct error variable
            toast.error("Payment failed.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const paymentData = getPaymentDataFromUrl();
        if (paymentData) {
            setPaymentData(paymentData);
            setAmount(paymentData.amount);
            setUtrno(paymentData.utr_no);
        } else {
            setLoading(false);
            toast.error("No payment data found.");
        }
    }, []);

    useEffect(() => {
        if (amount && utrno && paymentData?.status === 'success') {
            addpaymentFn();
        } else if (paymentData && paymentData.status !== 'success') {
            setLoading(false);
            toast.error("Payment failed.");
        }
    }, [amount, utrno, paymentData]);

    return (
        <div>
            {loading && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                    <div className="text-white text-center">
                        <p>Processing your payment, please wait...</p>
                        <div className="loader mt-4" />
                    </div>
                </div>
            )}

            {!loading && (
                <div style={{
                    backgroundImage: `url(${section1bg})`,
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                    overflow: "hidden",
                    backgroundPosition: 'center',
                }} className="min-h-screen">

                    <div className="absolute inset-0 bg-black opacity-80" />
                    <div className='2xl:max-w-[600px] max-w-[390px] border-[1px] border-white m-auto min-h-[100vh] pb-[100px]'>
                        <div className='mt-[75px] px-[20px]'>
                            {/* {paymentData ? (
                                <div>
                                    <h2 className="text-white">Payment Status: {paymentData.status}</h2>
                                    <p className="text-white">Message: {paymentData.msg}</p>
                                    <p className="text-white">UTR Number: {paymentData.utr_no}</p>
                                    <p className="text-white">Amount: ₹{paymentData.amount}</p>
                                </div>
                            ) : (
                                <div>
                                    <p className="text-white">Loading payment information...</p>
                                </div>
                            )} */}
                            <p className="text-white">Loading payment information...</p>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            )}
        </div>
    );
};

export default PaymentSuccessResponse;
