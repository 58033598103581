import React, { useState, useEffect } from 'react';
import { fetchUserPaddingReport, uploadVideo } from '../../components/Api/Api';
import { section1bg } from '../../images/Images';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdminSidebar } from '../AdminSidebar/AdminSidebar';

export const UserPanddingReport = () => {
    const [userPaddingReports, setUserPaddingReports] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [reportsPerPage] = useState(9);
    const [showModal, setShowModal] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);
    const getUserPaddingReport = async (token) => {
        try {
            const response = await fetchUserPaddingReport(token);
            setUserPaddingReports(response.data.data);
        } catch (error) {
            console.error('Error fetching user padding reports:', error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('admintoken');
        if (token) {
            getUserPaddingReport(token);
        } else {
            console.log('No token found in local storage');
        }
    }, []);

    const indexOfLastReport = currentPage * reportsPerPage;
    const indexOfFirstReport = indexOfLastReport - reportsPerPage;
    const currentReports = userPaddingReports.slice(indexOfFirstReport, indexOfLastReport);
    const totalPages = Math.ceil(userPaddingReports.length / reportsPerPage);

    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleUploadClick = (report) => {
        setSelectedReport(report);
        setShowModal(true);
        setIsUploading(false);
        setUploadProgress(0);
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file && selectedReport) {
            setIsUploading(true);

            const token = localStorage.getItem('admintoken');

            try {
                let response = await uploadVideo(
                    file,
                    selectedReport._id,
                    token,
                    (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(progress);
                    }
                );
                console.log('Video uploaded successfully',response);

                if(response.data.message === "Video report updated successfully."){
                    setIsUploading(false);
                    setShowModal(false);
                    toast.success(response.data.message);
                    getUserPaddingReport(token);
                }else{
                    toast.error(response.data.message);
                }
                
            } catch (error) {
                console.error('Error uploading video:', error);
                setIsUploading(false);
            }
        }
    };

    return (
        <div className="flex">
            <AdminSidebar />
            <div
                style={{
                    backgroundImage: `url(${section1bg})`,
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                    overflow: 'hidden',
                    backgroundPosition: 'center',
                }}
                className="min-h-screen w-full px-8 py-6 relative md:w-[80%] md:ml-[20%]"
            >
                <div className="absolute inset-0 bg-black opacity-60" />
                <div className="relative z-[99]">
                    <div className="border-b-[1px] border-white w-full py-[13px] mb-6">
                        <h1 className="text-white font-inter font-bold text-2xl">User Pending Report List</h1>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {currentReports.length > 0 ? (
                            currentReports.map((report, index) => (
                                <div key={report._id} className="bg-white rounded-lg shadow-lg p-6 relative">
                                    <p className='absolute top-0 left-0 px-2 py-1 text-white rounded-b-lg bg-custom-pink rounded-tl-lg'>
                                        {indexOfFirstReport + index + 1}
                                    </p>
                                    <h2 className="text-xl font-bold mb-2">{report.firstName} {report.lastName}</h2>
                                    <p className="text-gray-700"><strong>Father's Name:</strong> {report.fatherName}</p>
                                    <p className="text-gray-700"><strong>Birth Place:</strong> {report.birthPlace}</p>
                                    <p className="text-gray-700"><strong>Date of Birth:</strong> {report.dateOfBirth}</p>
                                    <p className="text-gray-700"><strong>Occupation:</strong> {report.occupation}</p>
                                    <p className="text-gray-700"><strong>Marital Status:</strong> {report.maritalStatus}</p>
                                    <p className="text-gray-700"><strong>Gender:</strong> {report.gender}</p>
                                    <p className="text-gray-700"><strong>Birth Time:</strong> {report.birthTime}</p>

                                    <p className={`font-semibold mt-3 ${report.reportStatus === 1 ? 'text-red-500' : 'text-green-500'}`}>
                                        Status: {report.reportStatus === 1 ? 'Pending' : 'Completed'}
                                    </p>

                                    <button
                                        className="absolute bottom-4 right-4 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md transition duration-200"
                                        onClick={() => handleUploadClick(report)}
                                    >
                                        Upload Report
                                    </button>
                                </div>
                            ))
                        ) : (
                            <div className="text-white text-xl col-span-full">
                                No pending reports available.
                            </div>
                        )}
                    </div>

                    {totalPages > 1 && (
                        <div className="mt-6 flex justify-center">
                            <button
                                onClick={() => handleClick(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="bg-gray-300 text-gray-700 px-4 py-2 mx-1 rounded hover:bg-gray-400"
                            >
                                Previous
                            </button>
                            {[...Array(totalPages)].map((_, i) => (
                                <button
                                    key={i}
                                    onClick={() => handleClick(i + 1)}
                                    className={`px-4 py-2 mx-1 rounded ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'
                                        }`}
                                >
                                    {i + 1}
                                </button>
                            ))}
                            <button
                                onClick={() => handleClick(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className="bg-gray-300 text-gray-700 px-4 py-2 mx-1 rounded hover:bg-gray-400"
                            >
                                Next
                            </button>
                        </div>
                    )}

                    {/* Modal for video upload */}
                    {showModal && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="bg-white rounded-lg p-8 w-[400px]">
                                <h2 className="text-xl font-bold mb-4">Upload Video for {selectedReport?.firstName}</h2>
                                <input
                                    type="file"
                                    accept="video/*"
                                    onChange={handleFileUpload}
                                    className="mb-4"
                                />

                                {isUploading && (
                                    <>
                                        <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
                                            <div
                                                className="bg-blue-600 h-4 rounded-full"
                                                style={{ width: `${uploadProgress}%` }}
                                            ></div>
                                        </div>
                                        <p>{uploadProgress}%</p>
                                    </>
                                )}

                                <button
                                    className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
                                    onClick={() => setShowModal(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};
