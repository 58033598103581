import logo from './logo.svg';
import section1bg from "./bg1.jpg";
import bg2 from "./Rectangle 1370.jpg";
import circle_groupe from "./cricle_groupe.svg";
import circle_astrology from './circle_astrology.svg';
import circle_gif from "./hand_bg.png";
import grid_bg from "./grid.png";
import shadow1 from "./shadow1.png";
import slider_img_1 from "./slider_image_1.jpg";
import slider_img_2 from "./slider_img_2.jpg";
import slider_img_3 from "./slider_img_3.jpg";
import top_vectore from "./top_Vector.svg";
import shadow2 from "./shadow2.png";
import rightarrow from "./right_aarow.svg";
import leftarrow from "./left_arrow.svg";
import star from "./star.png";
import section3_bg from "./background_img2.svg";
import chakrview from "./chakrview.png";
import section3_bg2 from "./section3_bg.svg";
import NeelimaTarot from "./Neelima_Tarot.svg";
import question_Card from "./question_card.svg";
import video_bg from "./video_bg.jpg";
import video_play from "./play.svg";
import clint_say_bg1 from "./our_clint_1.svg";
import clint_say_bg2 from "./our_clint_2.svg";
import clint1 from "./our_clint_img1.jpg";
import gif from './gif.gif';
import loader from './loader.gif';
import login_bg from "./login_bg.jpg";
import walleticon from "./wallet.png";
import home_icon from "./home_red.png";
import user_icon from "./user.png";
import history_icon from "./history_red.png";
import report_icon from "./file.png";
export {
    home_icon,
    user_icon,
    history_icon,
    report_icon,
    walleticon,
    gif,
    login_bg,
    loader,
    clint_say_bg1,
    clint_say_bg2,
    clint1,
    section3_bg2,
    chakrview,
    section3_bg,
    rightarrow,
    leftarrow,
    logo,
    section1bg,
    bg2,
    circle_groupe,
    circle_astrology,
    circle_gif,
    grid_bg,
    shadow1,
    slider_img_1,
    slider_img_2,
    slider_img_3,
    top_vectore,
    shadow2,
    star,
    NeelimaTarot,
    question_Card,
    video_bg,
    video_play
};
