import axios from 'axios';

// const BASE_URL = 'http://localhost:7000/api/v1/astrology';
const BASE_URL = 'http://139.59.71.108:7000/api/v1/astrology';


export const registerUser = async (userData) => {
    const response = await fetch(`${BASE_URL}/UserRegister`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(userData),
    });

    if (!response.ok) {
        throw new Error('Registration failed');
    }

    return await response.json();
};

export const sendOtp = async (email) => {
    const response = await axios.post(`${BASE_URL}/sendOtp`, { email });
    return response.data;
};

export const fetchUserInfo = async (token) => {
    try {
        const response = await axios.post(`${BASE_URL}/getUserInfo`, { token });
        return response.data;
    } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
    }
};

export const addPayment = async (token, amount) => {
    try {
        const response = await axios.post(`${BASE_URL}/addPayment`, {
            token,
            amount: amount.toString(), 
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });

        return response.data; 
    } catch (error) {
        console.error('Error adding payment:', error);
        throw error; 
    }
};

export const AddReport = async (payload) => { 
    try {
        const response = await axios.post(`${BASE_URL}/UserReportAdd`, payload, {  // Send payload directly
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        return response; 
    } catch (error) {
        console.error('Error adding report:', error);  // Adjusted log message
        throw error; 
    }
};


export const fetchPaymenthistoryData = async (token) => {
    try {
        const response = await axios.post(`${BASE_URL}/getUserPaymentHistory`, { token });
        return response.data;
    } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
    }
};

export const fetchUserReportInfo = async (token) => {
    try {
        const response = await axios.post(`${BASE_URL}/getUserReport`, { token });
        return response.data;
    } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
    }
};

export const adminLogin = async (email, password) => {
    const loginData = {
        email: email,
        password: password,
    };

    try {
        const response = await fetch(`${BASE_URL}/adminLogin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(loginData),
        });

        if (!response.ok) {
            throw new Error('Registration failed');
        }
    
        return await response.json();

    } catch (error) {
        throw error; 
    }
};

export const fetchUserPaddingReport = async (token) => {
    try {
        const response = await axios.post(`${BASE_URL}/getUserPandingReport`, { token });
        return response.data;
    } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
    }
};


export const uploadVideo = async (file, userId, token, onUploadProgress) => {
    if (!file || !userId) {
      throw new Error('File and userId are required');
    }
  
    const formData = new FormData();
    formData.append('video', file);
    formData.append('userId', userId);
  
    try {
      const response = await axios.post(
        `${BASE_URL}/UserReportVideo`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: onUploadProgress, // Pass the progress function
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error uploading video:', error);
      throw error;
    }
  };


export const fetchUserSucessReport = async (token) => {
    try {
        const response = await axios.post(`${BASE_URL}/getUserSucessReport`, { token });
        return response.data;
    } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
    }
};

export const getAllUser = async (token) => {
    try {
        const response = await axios.post(`${BASE_URL}/getAllUser`, { token });
        return response.data;
    } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
    }
};


export const getAllUserPaymentList = async (token) => {
    try {
        const response = await axios.post(`${BASE_URL}/getAllUserPaymentList`, { token });
        return response.data;
    } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
    }
};

export const getDashbordInfo = async (token) => {
    try {
        const response = await axios.post(`${BASE_URL}/getDashbordInfo`, { token });
        return response.data;
    } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
    }
};

export const addQuestionCard = async (Questions, Price) => {
    try {
        const response = await fetch(`${BASE_URL}/addCard`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Questions,
                Price
            })
        });

        const data = await response.json();
        return data

        
    } catch (error) {
        return {
            success: false,
            message: error.message || "Something went wrong.",
        };
    }
};

export const getquestionCard = async () => {
    try {
        const response = await axios.post(`${BASE_URL}/getCardList`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
    }
};

export const deleteQuestionCard = async (id, token) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/deleteQuestionCard`,
        { id },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error deleting question card:', error);
      throw error;
    }
  };




