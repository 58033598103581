import React, { useState,useEffect } from 'react';
import { logo, section1bg } from '../../images/Images';
import { adminLogin } from '../../components/Api/Api';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('admintoken');
        if (token) {
            navigate('/admindashboard');
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');

        try {
            const data = await adminLogin(email, password);
            console.log('Login successful:', data);
            if(data.data.message === "Login successful"){
                localStorage.setItem('admintoken', data.data.token);
                toast.success(data.data.message);
                navigate('/admindashboard');
            }else{
                toast.error(data.data.message);
            }
        } catch (error) {
            setErrorMessage(error.message || 'An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            style={{
                backgroundImage: `url(${section1bg})`,
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
                overflow: 'hidden',
                backgroundPosition: 'center',
            }}
            className="min-h-screen relative flex items-center justify-center bg-gray-100 px-[20px]"
        >
            <div className="absolute inset-0 bg-black opacity-60" />

            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md relative z-[99]">
                <img src={logo} alt="logo" className="w-[200px] m-auto" />
                <h2 className="text-2xl font-bold mb-6 text-left font-dm-serif">Admin Login</h2>

                {errorMessage && (
                    <div className="mb-4 text-red-600 text-center">
                        {errorMessage}
                    </div>
                )}

                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
                            Email:
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                            placeholder="Enter your email"
                        />
                    </div>

                    <div className="mb-6">
                        <label htmlFor="password" className="block text-gray-700 font-bold mb-2">
                            Password:
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                            placeholder="Enter your password"
                        />
                    </div>

                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200"
                        disabled={loading}
                    >
                        {loading ? 'Logging in...' : 'Login'}
                    </button>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};
