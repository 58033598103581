import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./User_panel/components/Home/Home";
import { Login } from "./User_panel/components/Login/Login";
import { Registration } from "./User_panel/components/Registration/Registration";
import { UserProfile } from "./User_panel/components/UserProfile/UserProfile";
import { UserReportInfo } from "./User_panel/components/UserReportInfo/UserReportInfo";
import { AdminLogin } from "./User_panel/Admin_Components/AdminLogin/AdminLogin";
import { Admindashboard } from "./User_panel/Admin_Components/Admindashboard/Admindashboard";
import { loader } from "./User_panel/images/Images"; 
import { UserPanddingReport } from "./User_panel/Admin_Components/UserPanddingReport/UserPanddingReport";
import { UserSucessReportList } from "./User_panel/Admin_Components/UserSucessReportList/UserSucessReportList";
import { UserListView } from "./User_panel/Admin_Components/UserListView/UserListView";
import { PaymentHistory } from "./User_panel/Admin_Components/PaymentHistory/PaymentHistory";
import UserDashbord from "./User_panel/components/UserDashbord/UserDashbord";
import { AddPayment } from "./User_panel/components/AddPayment/AddPayment";
import PaymentSucessResponse from "./User_panel/components/PaymentSucessResponse/PaymentSucessResponse";
import { AddQuestionCard } from "./User_panel/Admin_Components/AddQuestionCard/AddQuestionCard";

const App = () => {
  const [loading, setLoading] = useState(true); 

 
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); 
    }, 2000); 
    return () => clearTimeout(timer); 
  }, []);

  if (loading) {
    return (
      <div style={styles.loaderContainer}>
        <img src={loader} alt="Loading..." style={styles.loader} className="w-[200px]" />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/userdashbord" element={<UserDashbord />} />
        <Route path="/userProfile" element={<UserProfile />} />
        <Route path="/addpayment" element={<AddPayment />} />
        <Route path="/reportInfo" element={<UserReportInfo />} />
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route path="/admindashboard" element={<Admindashboard />} />
        <Route path="/userpaddingreport" element={<UserPanddingReport />} />
        <Route path="/UserSucessReportList" element={<UserSucessReportList />} />
        <Route path="/UserListView" element={<UserListView />} />
        <Route path="/PaymentHistory" element={<PaymentHistory />} />
        <Route path="/paymentsucess" element={<PaymentSucessResponse />} />
        <Route path="/addcard" element={<AddQuestionCard />} />
      </Routes>
    </BrowserRouter>
  );
};

const styles = {
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0', 
  },
  loader: {
    width: '350px', 
    height: '350px',
  },
};

export default App;
