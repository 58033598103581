import React, { useEffect , useState  } from 'react'
import { useNavigate } from 'react-router-dom';
import { section1bg } from '../../images/Images';
import { getDashbordInfo } from '../../components/Api/Api';
import { AdminSidebar } from '../AdminSidebar/AdminSidebar';
export const Admindashboard = () => {
    const [totalreciveAmount, settotalreciveAmount] = useState(0);
    const [totaluser, settotaluser] = useState(0);
    const [totalReportPanding, settotalReportPanding] = useState(0);
    const [totalReportsucess, settotalReportsucess] = useState(0);
    const [totalReports, settotalReports] = useState(0);
    const navigate = useNavigate();

    const getUserPaddingReport = async (token) => {
        try {
            const response = await getDashbordInfo(token);
            settotalreciveAmount(response.data.totalreciveAmount);
            settotaluser(response.data.totaluser);
            settotalReportPanding(response.data.reportpaddingData);
            settotalReportsucess(response.data.reportsucessdata);
            settotalReports(response.data.totalreportarrive);
        } catch (error) {
            console.error('Error fetching user padding reports:', error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('admintoken');
        getUserPaddingReport(token);
        if (!token) {
            navigate('/adminlogin');
        }
    }, [navigate]);
    return (
        <div>
            <div>
                <AdminSidebar />
            </div>

            <div
                style={{
                    backgroundImage: `url(${section1bg})`,
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                    overflow: 'hidden',
                    backgroundPosition: 'center',
                }}
                className="min-h-screen px-[20px] py-[10px] md:w-[80%] md:ml-[20%]"
            >
                <div className="absolute inset-0 bg-black opacity-60" />
                <div className='relative z-[99]'>
                    <div className='grid  xl:grid-cols-4 grid-cols-2 lg:grid-cols-3 gap-[15px] w-[100%] '>
                        <div className='bg-white p-[20px] rounded-xl border-custom-red border-[2px]'>
                            <h1 className='text-[18px] font-inter font-semibold'>Total User</h1>
                            <p className='text-[18px] font-inter'>{totaluser}</p>
                        </div>

                        <div className='bg-white p-[20px] rounded-xl border-custom-red border-[2px]'>
                            <h1 className='text-[18px] font-inter font-semibold'>Report Panding</h1>
                            <p className='text-[18px] font-inter'>{totalReportPanding}</p>
                        </div>

                        <div className='bg-white p-[20px] rounded-xl border-custom-red border-[2px]'>
                            <h1 className='text-[18px] font-inter font-semibold'>Report Sucess</h1>
                            <p className='text-[18px] font-inter'>{totalReportsucess}</p>
                        </div>

                        <div className='bg-white p-[20px] rounded-xl border-custom-red border-[2px]'>
                            <h1 className='text-[18px] font-inter font-semibold'>Total Report</h1>
                            <p className='text-[18px] font-inter'>{totalReports}</p>
                        </div>

                        <div className='bg-white p-[20px] rounded-xl border-custom-red border-[2px]'>
                            <h1 className='text-[18px] font-inter font-semibold'>Recive Payment</h1>
                            <p className='text-[18px] font-inter'>₹{totalreciveAmount}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
