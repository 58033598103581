import React, { useEffect, useState } from 'react';
import { section1bg } from '../../images/Images';
import UserDashbordNavbar from '../UserDashbordNavbar/UserDashbordNavbar';
import UserDashbordBottomBar from '../UserDashbordBottomBar/UserDashbordBottomBar';
import { fetchUserReportInfo } from '../Api/Api';
import moment from 'moment';  // To format the date

export const UserReportInfo = () => {
    const [reportData, setReportData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Current page state
    const recordsPerPage = 7; // Records to display per page
    const [activeTab, setActiveTab] = useState('processing'); // Changed initial state to 'processing'

    const getPaymentHistory = async (token) => {
        try {
            const data = await fetchUserReportInfo(token);
            console.log(data.data.data);
            setReportData(data.data.data);
        } catch (error) {
            console.error('Error fetching payment history:', error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('usertoken');
        if (token) {
            getPaymentHistory(token);
        } else {
            console.log('No token found in local storage');
        }
    }, []);

    // Function to handle video download
    const downloadVideo = async (videoFilename) => {
        try {
            const response = await fetch(`http://139.59.71.108:7000/videos/${videoFilename}`, {
                method: 'HEAD' // Just to check if the file exists
            });
    
            if (response.ok) {
                const link = document.createElement('a');
                link.href = `http://139.59.71.108:7000/videos/${videoFilename}`;
                link.download = videoFilename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                alert('Video file not found or cannot be downloaded.');
            }
        } catch (error) {
            console.error('Error downloading video:', error);
            alert('An error occurred while downloading the video.');
        }
    };

    // Conditionally rendering the status with download link
    const renderReportStatus = (status, videoFilename) => {
        if (status === 1) {
            return <span className="text-red-500">Report is Processing</span>;
        } else {
            return (
                <span
                    onClick={() => downloadVideo(videoFilename)} // Call downloadVideo on click
                    className="text-green-500 underline cursor-pointer"
                >
                    Report Ready
                </span>
            );
        }
    };

    // Filter reports based on the active tab
    const filteredReports = reportData.filter(report => 
        (activeTab === 'ready' && report.reportStatus !== 1) || 
        (activeTab === 'processing' && report.reportStatus === 1)
    );

    // Pagination logic
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredReports.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(filteredReports.length / recordsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div
            style={{
                backgroundImage: `url(${section1bg})`,
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
                overflow: 'hidden',
                backgroundPosition: 'center',
            }}
            className=""
        >
            <div className="absolute inset-0 bg-black opacity-80" />
            <div className="2xl:max-w-[600px] max-w-[390px] border-[1px] border-white m-auto min-h-[100vh] pb-[100px]">
                <div>
                    <UserDashbordNavbar />
                </div>

                <div className="mt-[85px] px-[20px] text-white relative z-[99]">
                    <div className='py-[10px]'>
                        <h1 className='font-inter font-semibold text-[18px] text-center text-white'>REPORT INFORMATION</h1>
                    </div>
                    
                    {/* Tab Navigation */}
                    <div className="flex justify-around mb-4">
                        <button 
                            className={`px-4 py-2 ${activeTab === 'processing' ? 'bg-red-500 hover:bg-red-600' : 'bg-gray-500'} text-white rounded`} 
                            onClick={() => { setActiveTab('processing'); setCurrentPage(1); }}
                        >
                            Processing Reports
                        </button>
                        <button 
                            className={`px-4 py-2 ${activeTab === 'ready' ? 'bg-red-500 hover:bg-red-600' : 'bg-gray-500'} text-white rounded`} 
                            onClick={() => { setActiveTab('ready'); setCurrentPage(1); }}
                        >
                            Ready Reports
                        </button>
                    </div>

                    <div className="text-white">
                        {currentRecords.length > 0 ? (
                            currentRecords.map((report, index) => (
                                <div
                                    key={index}
                                    className="bg-white rounded-[20px] p-[10px] border-custom-pink border-[3px] mb-4"
                                >
                                    <h1 className="text-[18px] font-bold text-black">
                                        {report.firstName} {report.lastName}
                                    </h1>
                                    <div className='flex flex-row justify-between items-center'>
                                        <div>
                                            <p className="text-sm text-black">
                                                Report Date<br /> {moment(report.createdAt).format('DD MMM YYYY')}
                                            </p>
                                        </div>
                                        <div className="">
                                            <p className='text-black'>
                                                Status: {renderReportStatus(report.reportStatus, report.videoFilename)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No report information available for this tab.</p>
                        )}
                    </div>

                    {/* Pagination */}
                    {filteredReports.length > recordsPerPage && (
                        <div className="flex justify-center space-x-2 mt-4">
                            <button
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                                className="px-4 py-2 bg-gray-500 text-white rounded"
                            >
                                Prev
                            </button>
                            {[...Array(totalPages)].map((_, i) => (
                                <button
                                    key={i + 1}
                                    onClick={() => handlePageClick(i + 1)}
                                    className={`px-4 py-2 ${currentPage === i + 1 ? 'bg-blue-500' : 'bg-gray-500'} text-white rounded`}
                                >
                                    {i + 1}
                                </button>
                            ))}
                            <button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                                className="px-4 py-2 bg-gray-500 text-white rounded"
                            >
                                Next
                            </button>
                        </div>
                    )}
                </div>

                <div>
                    <UserDashbordBottomBar />
                </div>
            </div>
        </div>
    );
};
