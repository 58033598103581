import React, { useEffect, useState } from 'react';
import { login_bg, logo, section1bg } from '../../images/Images';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('usertoken');
        if (token) {
            navigate('/userdashbord');
        }
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            toast.error("Email and password are required!");
            return;
        }

        try {
            const response = await axios.post('http://139.59.71.108:7000/api/v1/astrology/UserLogin', {
                email,
                password,
            });

            if (response.data.data.message === "Login successful") {
                toast.success(response.data.data.message);
                localStorage.setItem('usertoken', response.data.data.token);
                navigate('/userdashbord'); // Redirect to user dashboard
            } else {
                toast.error(response.data.data.message);
            }
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.message || "Login failed!");
            } else {
                toast.error("An error occurred. Please try again.");
            }
        }
    };

    return (
        <div
            className="w-full h-screen flex items-center justify-center bg-cover bg-center"
            style={{
                backgroundImage: `url(${section1bg})`,
                backgroundSize: "cover",
                backgroundAttachment: "fixed",
                backgroundRepeat: "no-repeat",
                backgroundPosition: 'center',
            }}
        >
            <div className="absolute inset-0 bg-black opacity-60 z-[1]" />
            <div
                className="relative p-8 rounded-lg shadow-lg w-[400px] z-[10]"
                style={{
                    backgroundImage: `url(${login_bg})`,
                    backgroundSize: "100% 100%",
                    backgroundAttachment: "fixed",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: 'center',
                }}
            >
                <div className="absolute inset-0 bg-black opacity-60 rounded-lg" />
                {/* Logo */}
                <div className="text-center mb-6 z-[99] relative">
                    <img src={logo} alt="logo" className="w-[150px] mx-auto" />
                </div>

                {/* Login Form */}
                <form className='z-[99] relative' onSubmit={handleLogin}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-white">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none outline-none"
                            placeholder="Enter your email"
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="password" className="block text-white">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none outline-none"
                            placeholder="Enter your password"
                        />
                    </div>

                    {/* Registration Link */}
                    <div className="text-right mb-4">
                        <Link
                            to="/register"
                            className="text-custom-dark-red hover:text-custom-red transition duration-300"
                        >
                            Registration?
                        </Link>
                    </div>

                    <div className="text-center">
                        <button
                            type="submit"
                            className="w-full bg-custom-red text-white px-4 py-2 rounded-lg hover:bg-custom-dark-red"
                        >
                            Login
                        </button>
                    </div>
                    <div className="text-center mt-4">
                        <Link
                            to="/"
                            className="text-custom-dark-red hover:text-custom-red transition duration-300"
                        >
                            Go Back to website?
                        </Link>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};
