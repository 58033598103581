import React, { useEffect, useState } from 'react';
import { section1bg } from '../../images/Images';
import UserDashbordNavbar from '../UserDashbordNavbar/UserDashbordNavbar';
import UserDashbordBottomBar from '../UserDashbordBottomBar/UserDashbordBottomBar';
import { fetchUserInfo } from '../Api/Api';

export const UserProfile = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [isEditable, setIsEditable] = useState(false);

    const getUserInfo = async (token) => {
        try {
            const data = await fetchUserInfo(token);
            setUserInfo(data.data.data);
        } catch (error) {
            console.error('Error fetching user info:', error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('usertoken');
        if (token) {
            getUserInfo(token);
        } else {
            console.log('No token found in local storage');
        }
    }, []);

   

    // console.log("userInfo", userInfo);

    return (
        <div style={{
            backgroundImage: `url(${section1bg})`,
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundRepeat: "no-repeat",
            position: "relative",
            overflow: "hidden",
            backgroundPosition: 'center',
        }}
            className=" ">
            <div className="absolute inset-0 bg-black opacity-80" />
            <div className='2xl:max-w-[600px] max-w-[390px] border-[1px] border-white m-auto min-h-[100vh] pb-[100px] '>
                <div>
                    <UserDashbordNavbar />
                </div>

                <div className='relative z-[99]'>
                    <div className='min-h-[200px] bg-custom-red'></div>

                    <div className='mt-[-30px] w-[100%] flex justify-center'>
                        <h1 className='text-white text-[50px] font-inter font-bold bg-custom-dark-red  w-[70px] h-[70px] flex justify-center items-center rounded-[50px]'>
                            {userInfo ? userInfo.firstName.charAt(0).toUpperCase() : 'A'}
                        </h1>
                    </div>

                    <div className='px-[20px] mt-[25px]'>
                        {userInfo ? (
                            <div className="text-white flex flex-col gap-[10px]">
                                <div className='grid grid-cols-2 gap-[10px]'>
                                    <label className="block">
                                        <span>First Name </span>
                                        <input
                                            type="text"
                                            value={`${userInfo.firstName} `}
                                            readOnly={!isEditable}
                                            className=" text-white border-none outline-none user_profile_filed"
                                        />
                                    </label>

                                    <label className="block">
                                        <span>Last Name </span>
                                        <input
                                            type="text"
                                            value={` ${userInfo.lastName}`}
                                            readOnly={!isEditable}
                                            className=" text-white border-none outline-none user_profile_filed"
                                        />
                                    </label>
                                </div>

                                <label className="block">
                                    <span>Email </span>
                                    <input
                                        type="email"
                                        value={userInfo.email}
                                        readOnly={!isEditable}
                                        className=" text-white border-none outline-none user_profile_filed"
                                    />
                                </label>
                                <label className="block">
                                    <span>Date of Birth </span>
                                    <input
                                        type="date"
                                        value={userInfo.dob}
                                        readOnly={!isEditable}
                                        className=" text-white border-none outline-none user_profile_filed"
                                    />
                                </label>
                                <label className="block">
                                    <span>Birth Time</span>
                                    <input
                                        type="time"
                                        value={userInfo.birthTime}
                                        readOnly={!isEditable}
                                        className=" text-white border-none outline-none user_profile_filed"
                                    />
                                </label>
                                <label className="block">
                                    <span>Gender </span>
                                    <input
                                        type="text"
                                        value={userInfo.gender}
                                        readOnly={!isEditable}
                                        className=" text-white border-none outline-none user_profile_filed"
                                    />
                                </label>
                                <label className="block">
                                    <span>Phone Number </span>
                                    <input
                                        type="tel"
                                        value={userInfo.number}
                                        readOnly={!isEditable}
                                        className=" text-white border-none outline-none user_profile_filed"
                                    />
                                </label>
                            </div>
                        ) : (
                            <p className="text-white text-center">Loading user information...</p>
                        )}
                    </div>

                    {/* <div className="text-center mt-[20px]">
                        <button
                            onClick={handleUpdateClick}
                            className="bg-custom-dark-red text-white px-[20px] py-[10px] rounded"
                        >
                            {isEditable ? 'Save Profile' : 'Update Profile'}
                        </button>
                    </div> */}
                </div>

                <div>
                    <UserDashbordBottomBar />
                </div>
            </div>
        </div>
    );
};
