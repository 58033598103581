// src/components/UserDashbordAskcard/UserDashbordAskcard.jsx

import React, { useState, useEffect } from 'react';
import { question_Card } from '../../images/Images';
import { UserQuestionCard } from '../UserQuestionCard/UserQuestionCard';
import { fetchPaymenthistoryData, fetchUserInfo, getquestionCard } from '../Api/Api';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

Modal.setAppElement('#root');

export const UserDashbordAskcard = () => {
    // State variables
    const [isCardSelected, setIsCardSelected] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [TotalAmount, setTotalAmount] = useState(0);
    const [userInfo, setUserInfo] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showPriceModal, setShowPriceModal] = useState(false);
    const [questioncardData, setQuestioncardData] = useState([]);
    const token = localStorage.getItem('usertoken');
    const navigate = useNavigate();

    // Helper function to convert numbers to words (simple version, up to 20)
    const numberToWords = (num) => {
        const ones = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten",
            "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen",
            "Eighteen", "Nineteen", "Twenty"];
        if (num >= 0 && num <= 20) {
            return ones[num];
        }
        // For numbers greater than 20, just return the number as string
        return num;
    };

    // Function to open a card
    const openCard = (card) => {
        const questionCount = parseInt(card.Questions, 10);
        const price = parseInt(card.Price, 10);

        if (TotalAmount >= price) {
            setSelectedCard({ ...card, price });
            setShowPriceModal(true);
        } else {
            toast.error(
                <div>
                    <p className='text-red-700'>Insufficient wallet balance. Please add funds to your wallet.</p>
                    <button
                        onClick={() => {
                            toast.dismiss();
                            navigate("/addpayment");
                        }}
                        className="mt-2 bg-red-500 hover:bg-red-600 text-white py-1 px-4 rounded"
                    >
                        OK
                    </button>
                </div>,
                { autoClose: false }
            );
        }
    };

    // Function to cancel card selection
    const handleCancel = () => {
        setIsCardSelected(false);
        setSelectedCard(null);
    };

    // Function to confirm card selection
    const handleConfirmation = () => {
        setShowModal(false);
        setIsCardSelected(true);
    };

    const getPaymentHistory = async (token) => {
        try {
            const data = await fetchPaymenthistoryData(token);
            if (data.data.messages === "user  get payment History successful") {
                setTotalAmount(parseInt(data.data.totalAmount, 10));
            } else {
            }
        } catch (error) {
            console.error('Error fetching payment history:', error);
        }
    };

    const getUserInfo = async (token) => {
        try {
            if (!userInfo) {
                const data = await fetchUserInfo(token);
                if (data.data.messages === "user info get successful") {
                    setUserInfo(data.data.data);
                } else {
                }
            }
        } catch (error) {
            console.error('Error fetching user info:', error);
        }
    };

    const handlePriceConfirmation = () => {
        setShowPriceModal(false);
        setShowModal(true);
        if (!userInfo) {
            getUserInfo(token);
        }
    };

    const getQuestionCardData = async () => {
        try {
            const response = await getquestionCard(token);
            console.log("Fetched Question Cards Response:", response);

            if (response.statusCode === 200 && response.type === "SUCCESS") {
                const fetchedData = response.data.data;

                const filteredData = fetchedData.filter(card => card.isEnabled && !card.isDeleted);

                setQuestioncardData(filteredData);
            } else {
                toast.error(response.message || 'Failed to fetch question card data.');
            }
        } catch (error) {
            console.error('Error fetching question card data:', error);
        }
    };

    useEffect(() => {
        if (token) {
            getPaymentHistory(token);
            getUserInfo(token);
            getQuestionCardData();
        } else {
            console.log('No token found in local storage');
        }
    }, [token]);

    console.log("Question Card Data:", TotalAmount);

    const closeModal = () => {
        setShowModal(false);
        setIsCardSelected(true);
    };

    return (
        <div className='slider-section 2xl:max-w-[600px] max-w-[380px] m-auto mt-[25px] relative'>
            {!isCardSelected ? (
                <>
                    <div className='text-center'>
                        <h1 className="font-dm-serif lg:text-[30px] text-center md:text-[25px] text-white sm:text-[20px] text-[20px] border-b-[1px] border-custom-red inline-block">
                            Ask the Stars
                        </h1>
                        <p className="text-[14px] text-white max-w-[800px] m-auto lg:mt-[12px]">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </p>
                    </div>

                    {/* Cards Grid */}
                    <div className='mt-[25px] grid grid-cols-2 gap-[15px] user_dashbord_Ask_card'>
                        {questioncardData.length > 0 ? (
                            questioncardData.map((card) => {
                                const questionCount = parseInt(card.Questions, 10);
                                const price = parseInt(card.Price, 10);
                                const text = `${numberToWords(questionCount)} ${questionCount === 1 ? 'Question' : 'Questions'}`;

                                return (
                                    <div
                                        key={card._id}
                                        className="relative cursor-pointer"
                                        onClick={() => openCard(card)}
                                    >
                                        <img
                                            src={question_Card}
                                            alt={text}
                                            className='sm:w-full w-[149px]'
                                        />
                                        <div className="absolute inset-0 flex items-center justify-center text-white font-bold sm:text-[18px] text-[12px]">
                                            <div className='flex flex-col items-center'>
                                                <p>{text}</p>
                                                <p> ₹{price}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className='col-span-2 text-center text-white'>No data available</div>
                        )}
                    </div>
                </>
            ) : (
                <UserQuestionCard selectedCard={selectedCard} handleCancel={handleCancel} userInfo={userInfo} />
            )}

            <Modal
                isOpen={showPriceModal}
                onRequestClose={() => setShowPriceModal(false)}
                contentLabel="Price Confirmation"
                className="modal-class"
                overlayClassName="custom-modal-overlay"
            >
                <div className="modal-content">
                    <h2 className="text-2xl font-semibold text-center mb-4">Confirm Your Selection</h2>
                    <p className="text-lg text-center mb-4">
                        You are about to ask <strong>{numberToWords(parseInt(selectedCard?.Questions, 10))} {parseInt(selectedCard?.Questions, 10) === 1 ? 'Question' : 'Questions'}</strong> for <strong>₹{selectedCard?.price}</strong>.
                    </p>
                    <div className="modal-actions flex justify-between">
                        <button
                            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md"
                            onClick={handlePriceConfirmation}
                        >
                            Yes, Proceed
                        </button>
                        <button
                            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                            onClick={() => setShowPriceModal(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={showModal}
                onRequestClose={closeModal}
                contentLabel="User Info Confirmation"
                className="modal-class"
                overlayClassName="custom-modal-overlay"
            >
                <div className="modal-content relative">
                    <button
                        className="absolute top-[-10px] right-[-15px] text-[20px] bg-red-500 w-[30px] h-[30px] rounded-[50px] text-white hover:text-black"
                        onClick={closeModal}
                    >
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </button>

                    <h2 className="text-2xl font-semibold text-center mb-4">Do you need this information?</h2>

                    <div className="space-y-2 text-lg">
                        <p className='text-[16px] flex gap-[2px]'>
                            <strong>Name:</strong> {userInfo?.firstName} {userInfo?.lastName}
                        </p>
                        <p className='text-[16px] flex gap-[2px]'>
                            <strong>Email:</strong> {userInfo?.email}
                        </p>
                        <p className='text-[16px] flex gap-[2px]'>
                            <strong>Phone:</strong> {userInfo?.number}
                        </p>
                        <p className='text-[16px] flex gap-[2px]'>
                            <strong>Date of Birth:</strong> {userInfo?.dob}
                        </p>
                        <p className='text-[16px] flex gap-[2px]'>
                            <strong>Birth Time:</strong> {userInfo?.birthTime}
                        </p>
                    </div>

                    <div className="modal-actions flex justify-between mt-6">
                        <button
                            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md"
                            onClick={handleConfirmation}
                        >
                            Yes, Proceed
                        </button>
                        <button
                            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                            onClick={closeModal}
                        >
                            No
                        </button>
                    </div>
                </div>
            </Modal>
            <style jsx>{`
                .modal-class {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 340px;
                    background: white;
                    border-radius: 8px;
                    padding: 20px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                }

                .custom-modal-overlay {
                    background-color: rgba(0, 0, 0, 0.75);
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            `}</style>

            <ToastContainer />
        </div>
    );
};
