import React from 'react';
import Navbar from '../Navbar/Navbar';
import { Footer } from '../Footer/Footer';
import TopSection from '../TopSection/TopSection';
import WhatWeDo from '../WhatWeDo/WhatWeDo';
import ChooseYourFuture from '../ChooseYourFuture/ChooseYourFuture';
import VideoSection from '../VideoSection/VideoSection';
import OurClientsSays from '../OurClientsSays/OurClientsSays';
import { NellimaAstrology } from '../NellimaAstrology/NellimaAstrology';

const Home = () => {

    return (
        <div>
            <>
                <Navbar />
                <div id="Top_Section">
                    <TopSection />
                </div>
                <div id="What_We_Do">
                    <WhatWeDo />
                </div>
                <div id="Choose_your_future">
                    <ChooseYourFuture />
                </div>
                <div id="Neelima_Tarot">
                    <NellimaAstrology />
                </div>
                <div>
                    <VideoSection />
                </div>
                <div>
                    <OurClientsSays />
                </div>
                <Footer />
            </>

        </div>
    );
};



export default Home;
