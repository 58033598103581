import React, { useState, useEffect } from 'react';
import { getAllUser } from '../../components/Api/Api';
import { section1bg } from '../../images/Images';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdminSidebar } from '../AdminSidebar/AdminSidebar';

export const UserListView = () => {
    const [userPaddingReports, setUserPaddingReports] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [reportsPerPage] = useState(10); // Show 10 records per page
    const token = localStorage.getItem('admintoken');

    const getUserPaddingReport = async (token) => {
        try {
            const response = await getAllUser(token);
            setUserPaddingReports(response.data.data);
        } catch (error) {
            console.error('Error fetching user padding reports:', error);
        }
    };

    useEffect(() => {
        if (token) {
            getUserPaddingReport(token);
        } else {
            console.log('No token found in local storage');
        }
    }, [token]);

    const indexOfLastReport = currentPage * reportsPerPage;
    const indexOfFirstReport = indexOfLastReport - reportsPerPage;
    const currentReports = userPaddingReports.slice(indexOfFirstReport, indexOfLastReport);
    const totalPages = Math.ceil(userPaddingReports.length / reportsPerPage);

    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="flex">
            <AdminSidebar />
            <div
                style={{
                    backgroundImage: `url(${section1bg})`,
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                    overflow: 'hidden',
                    backgroundPosition: 'center',
                }}
                className="min-h-screen w-full px-8 py-6 relative md:w-[80%] md:ml-[20%]"
            >
                <div className="absolute inset-0 bg-black opacity-60" />
                <div className="relative z-[99]">
                    <div className="border-b-[1px] border-white w-full py-[13px] mb-6">
                        <h1 className="text-white font-inter font-bold text-2xl">User List</h1>
                    </div>

                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border border-gray-300 rounded-lg">
                            <thead>
                                <tr className="bg-gray-100">
                                    <th className="py-2 px-4 table-cell-nowrap border-b text-left text-gray-700">Sr.no</th>
                                    <th className="py-2 px-4 table-cell-nowrap border-b text-left text-gray-700">Name</th>
                                    <th className="py-2 px-4 table-cell-nowrap border-b text-left text-gray-700">Date of Birth</th>
                                    <th className="py-2 px-4 table-cell-nowrap border-b text-left text-gray-700">email</th>
                                    <th className="py-2 px-4 table-cell-nowrap border-b text-left text-gray-700">Gender</th>
                                    <th className="py-2 px-4 table-cell-nowrap border-b text-left text-gray-700">Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentReports.length > 0 ? (
                                    currentReports.map((report, index) => (
                                        <tr key={report._id} className="hover:bg-gray-50">
                                            <td className="py-2 px-4 border-b table-cell-nowrap">{indexOfFirstReport + index + 1}</td>
                                            <td className="py-2 px-4 border-b table-cell-nowrap">{report.firstName} {report.lastName}</td>
                                            <td className="py-2 px-4 border-b table-cell-nowrap">{report.dob}</td>
                                            <td className="py-2 px-4 border-b table-cell-nowrap">{report.email}</td>
                                            <td className="py-2 px-4 border-b table-cell-nowrap">{report.gender}</td>
                                            <td className="py-2 px-4 border-b table-cell-nowrap">{report.number}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="9" className="text-gray-700 text-center py-4">No pending reports available.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {totalPages > 1 && (
                        <div className="mt-6 flex justify-center">
                            <button
                                onClick={() => handleClick(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="bg-gray-300 text-gray-700 px-4 py-2 mx-1 rounded hover:bg-gray-400"
                            >
                                Previous
                            </button>
                            {[...Array(totalPages)].map((_, i) => (
                                <button
                                    key={i}
                                    onClick={() => handleClick(i + 1)}
                                    className={`px-4 py-2 mx-1 rounded ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
                                >
                                    {i + 1}
                                </button>
                            ))}
                            <button
                                onClick={() => handleClick(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className="bg-gray-300 text-gray-700 px-4 py-2 mx-1 rounded hover:bg-gray-400"
                            >
                                Next
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};
