import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { section1bg } from '../../images/Images';
import UserDashbordNavbar from '../UserDashbordNavbar/UserDashbordNavbar';
import UserDashbordBottomBar from '../UserDashbordBottomBar/UserDashbordBottomBar';
import { UserdashbordSliderSection } from '../UserdashbordSliderSection/UserdashbordSliderSection';
import {  UserDashbordAskcard } from '../UserDashbordAskcard/UserDashbordAskcard';

const UserDashbord = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in
        const token = localStorage.getItem('usertoken');
        if (!token) {
            navigate('/login');
        }
    }, [navigate]);

    return (
        <div style={{
            backgroundImage: `url(${section1bg})`,
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundRepeat: "no-repeat",
            position: "relative",
            overflow: "hidden",
            backgroundPosition: 'center',
        }}
            className=" ">
            <div className="absolute inset-0 bg-black opacity-80" />
            <div className='2xl:max-w-[600px] max-w-[390px] border-[1px] border-white m-auto min-h-[100vh] pb-[100px]'>
                <div>
                    <UserDashbordNavbar />
                </div>

                <div className='mt-[75px] px-[20px]'>
                    <div>
                        <UserdashbordSliderSection />
                    </div>

                    <div>
                        <UserDashbordAskcard />
                    </div>
                </div>

                <div>
                    <UserDashbordBottomBar />
                </div>
            </div>
        </div>

    );
};

export default UserDashbord;
