import React, { useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { login_bg, logo, section1bg } from '../../images/Images';
import { registerUser, sendOtp } from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Registration = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        dob: '',
        birthTime: '',
        number: '',
        gender: 'male',
        password: '',
    });

    const [otp, setOtp] = useState('');
    const [otpStatus, setOtpStatus] = useState(null);
    const [currectOtp, setCurrectOtp] = useState('');
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

        if (name === 'otp') {
            setOtp(value);
            if (value.length === 6 && /^[0-9]+$/.test(value)) {
                setOtpStatus(value === currectOtp ? 'success' : 'error');
            } else {
                setOtpStatus('error');
            }
        }
    };

    const handleSendOtp = async () => {
        try {
            const response = await sendOtp(formData.email);
            if (response.data && response.data.message === "OTP sent successfully") {
                toast.success(response.data.message);
                setCurrectOtp(response.data.otp);
                setOtpStatus(null);
            } else {
                toast.error(response.data?.message || "Failed to send OTP.");
                setOtpStatus('error');
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            toast.error("An error occurred while sending OTP.");
            setOtpStatus('error');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate OTP
        if (otp.length !== 6) {
            toast.error("Please enter a valid 6-digit OTP.");
            setOtpStatus('error');
            return;
        }

        // Register the user without sending OTP
        try {
            const { otp, ...registrationData } = formData; // Exclude OTP
            const response = await registerUser(registrationData);
            if(response.data.message === "User registered successfully!"){
                toast.success("Registration successful!");
                navigate('/login');
            }
            else{
                toast.error(response.data.message);
            }
            
        } catch (error) {
            console.error('Error during registration:', error);
            toast.error("Registration failed.");
        }
    };

    return (
        <div
            className="w-full flex items-center justify-center bg-cover bg-center relative"
            style={{
                backgroundImage: `url(${section1bg})`,
                backgroundSize: "cover",
                backgroundAttachment: "fixed",
                backgroundRepeat: "no-repeat",
                backgroundPosition: 'center',
            }}
        >
            <div className="absolute h-full inset-0 bg-black opacity-60 z-[1]" />
            <div
                className="relative p-8 rounded-lg shadow-lg w-full lg:max-w-[500px] 2xl:max-w-[600px] z-[10] bg-white my-[100px]"
                style={{
                    backgroundImage: `url(${login_bg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: 'center',
                }}
            >
                <div className="absolute inset-0 bg-black h-full opacity-60 rounded-lg" />
                <div className="text-center mb-6 z-[99] relative">
                    <img src={logo} alt="logo" className="w-[150px] mx-auto" />
                </div>
                <form onSubmit={handleSubmit} className="z-[99] relative">
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                            <label htmlFor="firstName" className="block text-white">First Name</label>
                            <input
                                type="text"
                                name="firstName"
                                id="firstName"
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none outline-none"
                                placeholder="Enter your first name"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="lastName" className="block text-white">Last Name</label>
                            <input
                                type="text"
                                name="lastName"
                                id="lastName"
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none outline-none"
                                placeholder="Enter your last name"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-white">Email</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none outline-none"
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-4 grid grid-cols-2 gap-4">
                        <div>
                            <label htmlFor="otp" className="block text-white">OTP</label>
                            <input
                                type="text"
                                name="otp"
                                id="otp"
                                maxLength="6" // Limit input to 6 characters
                                className={`w-full px-3 py-2 border rounded-lg focus:outline-none outline-none ${otpStatus === 'success' ? 'border-green-500' : otpStatus === 'error' ? 'border-red-500' : ''}`}
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="flex items-end">
                            <button
                                type="button"
                                onClick={handleSendOtp}
                                className="w-full bg-custom-red text-white px-4 py-2 rounded-lg hover:bg-custom-dark-red"
                            >
                                Send OTP
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                            <label htmlFor="dob" className="block text-white">Date of Birth</label>
                            <input
                                type="date"
                                name="dob"
                                id="dob"
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none outline-none"
                                value={formData.dob}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="birthTime" className="block text-white">Birth Time</label>
                            <input
                                type="time"
                                name="birthTime"
                                id="birthTime"
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none outline-none"
                                value={formData.birthTime}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="mb-6">
                        <label htmlFor="whatsappNumber" className="block text-white">WhatsApp Number</label>
                        <input
                            type="tel"
                            name="number"
                            id="whatsappNumber"
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none outline-none"
                            placeholder="Enter your WhatsApp number"
                            value={formData.number}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-white">Gender</label>
                        <div className="flex items-center">
                            <label className="mr-4 text-white">
                                <input
                                    type="radio"
                                    name="gender"
                                    value="male"
                                    checked={formData.gender === 'male'}
                                    onChange={handleChange}
                                    className="mr-2 text-white"
                                />
                                Male
                            </label>
                            <label className='text-white'>
                                <input
                                    type="radio"
                                    name="gender"
                                    value="female"
                                    checked={formData.gender === 'female'}
                                    onChange={handleChange}
                                    className="mr-2 text-white"
                                />
                                Female
                            </label>
                        </div>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-white">Password</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none outline-none"
                            placeholder="Enter your password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-custom-red text-white px-4 py-2 rounded-lg hover:bg-custom-dark-red"
                    >
                        Register
                    </button>
                    <div className="mt-4 text-center text-white">
                        Already have an account? <Link to="/login" className="text-blue-400">Login</Link>
                    </div>
                </form>
                <ToastContainer />
            </div>
        </div>
    );
};
