import React from 'react';
import { logo } from '../../images/Images';
import { Link } from 'react-router-dom';

// Dynamic menu items array
const menuItems = [
  { name: 'Dashboard', path: '/admindashboard' },
  { name: 'User List', path: '/UserListView' },
  { name: 'Pending Report', path: '/userpaddingreport' },
  { name: 'Success Report', path: '/UserSucessReportList' },
  { name: 'Payment History', path: '/PaymentHistory' },
  { name: 'Add Question Card', path: '/addcard' },
];

export const AdminSidebar = () => {
  return (
    <div className="md:w-[20%] bg-black h-screen z-[99999] text-white fixed flex-col float-left border-r-[1px] border-white">
      {/* Logo Section */}
      <div className="p-2 border-b border-gray-700">
        <img src={logo} className="w-[120px] " alt="Logo" />
      </div>

      {/* Dynamic Navigation Menu */}
      <div className="flex-1 mt-4">
        <ul className="space-y-4">
          {menuItems.map((item, index) => (
            <li key={index}>
              <Link to={item.path} className="block py-2 px-4 hover:bg-gray-700">
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      {/* Footer Section (optional) */}
      <div className="p-4 border-t border-gray-700">
        <p className="text-sm text-center">© 2024 Admin Panel</p>
      </div>
    </div>
  );
};
