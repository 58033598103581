import React from 'react';
import { history_icon, home_icon, report_icon, user_icon } from '../../images/Images';

const UserDashbordBottomBar = () => {
    // Dynamic menu items array
    const bottomBarItems = [
        { icon: user_icon, label: 'USER', link: '/userProfile' },
        { icon: home_icon, label: 'HOME', link: '/userdashbord' },
        { icon: report_icon, label: 'REPORT', link: '/reportInfo' },
        { icon: history_icon, label: 'PAYMENT', link: '/addpayment' },
    ];

    return (
        <div className=' fixed w-[100%] bottom-0 z-[9999] 2xl:max-w-[600px] sm:max-w-[390px] m-auto bg-white py-[10px] px-[20px]'>
            <div className='flex flex-row justify-between items-center text-center'>
                {bottomBarItems.map((item, index) => (
                    <div key={index} className='flex flex-col items-center gap-[5px]'>
                        <a href={item.link}>
                            <img src={item.icon} alt={item.label} className='2xl:w-[35px] max-w-[24px]' />
                        </a>
                        <p className='font-inter text-[12px]'>{item.label}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserDashbordBottomBar;
