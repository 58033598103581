import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { AddReport } from '../Api/Api';

export const UserQuestionCard = ({ selectedCard, handleCancel, userInfo }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        fatherName: '',
        dateOfBirth: '',
        maritalStatus: '',
        birthTime: '',
        birthPlace: '',
        gender: '',
        occupation: '',
        partnerName: '',
        partnerBirthDate: '',
        partnerBirthTime: '',
        partnerBirthPlace: '',
        questions: Array(Number(selectedCard.Questions) || 0).fill(''),
    });

    // Auto-fill form if userInfo is available
    useEffect(() => {
        if (userInfo) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                firstName: userInfo.firstName || '',
                lastName: userInfo.lastName || '',
                fatherName: userInfo.fatherName || '',
                dateOfBirth: userInfo.dateOfBirth || '',
                maritalStatus: userInfo.maritalStatus || '',
                birthTime: userInfo.birthTime || '',
                birthPlace: userInfo.birthPlace || '',
                gender: userInfo.gender || '',
                occupation: userInfo.occupation || '',
            }));
        } else {
            setFormData({
                firstName: '',
                lastName: '',
                fatherName: '',
                dateOfBirth: '',
                maritalStatus: '',
                birthTime: '',
                birthPlace: '',
                gender: '',
                occupation: '',
                partnerName: '',
                partnerBirthDate: '',
                partnerBirthTime: '',
                partnerBirthPlace: '',
                questions: Array(Number(selectedCard.Questions) || 0).fill(''),
            });
        }
    }, [userInfo, selectedCard]);

    // Handle form changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith('question_')) {
            const index = parseInt(name.split('_')[1], 10);
            const updatedQuestions = [...formData.questions];
            updatedQuestions[index] = value;
            setFormData({
                ...formData,
                questions: updatedQuestions,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    // Step navigation
    const nextStep = () => setCurrentStep(currentStep + 1);
    const prevStep = () => setCurrentStep(currentStep - 1);

    // Handle payment submission
    const handlePayment = async () => {
        const payload = {
            ...formData,
            userId: userInfo?._id,
            reportAmount: selectedCard.price,
        };

        try {
            const response = await AddReport(payload);
            if (response.data.data.messages === "User report added successfully") {
                toast.success(response.data.data.messages);
                navigate('/reportInfo');
            } else {
                toast.error(response.data.data.messages);
            }
        } catch (error) {
            toast.error('Error saving report. Please try again.');
            console.error('Error saving report:', error);
        }
    };

    return (
        <div className="text-center text-white p-5 bg-[#d62a262b] user_question_Card rounded-lg">
            <h2 className="font-bold text-[20px] mb-4 mt-[15px]">You selected: {selectedCard?.text || 'No selection made'}</h2>
            <button onClick={handleCancel} className="mt-4 bg-red-500 text-white px-[7px] py-[2px] rounded-[50px] cancel_question_card absolute right-0 top-[-10px]">
                <i className="fa fa-times" aria-hidden="true"></i>
            </button>

            {/* Progress Bar */}
            <div className="progress-bar w-full h-[4px] bg-gray-200 my-2">
                <div className={`h-full bg-custom-red`} style={{ width: `${(currentStep / 4) * 100}%` }}></div>
            </div>

            {/* Form Steps */}
            {currentStep === 1 && (
                <div>
                    <h3 className="text-[15px] font-bold text-left font-inter">Personal Information</h3>
                    <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="First Name" className="w-full p-2 my-2 border question_card_fild" />
                    <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Last Name" className="w-full p-2 my-2 border question_card_fild" />
                    <input type="text" name="fatherName" value={formData.fatherName} onChange={handleChange} placeholder="Father's Name" className="w-full p-2 my-2 border question_card_fild" />
                    <input type="date" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange} className="w-full p-2 my-2 border question_card_fild" />
                    <select name="maritalStatus" value={formData.maritalStatus} onChange={handleChange} className="w-full p-2 my-2 border question_card_fild">
                        <option value="">Marital Status</option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                    </select>
                    {formData.maritalStatus === 'Married' && (
                        <div className="mt-4">
                            <h3 className="text-lg font-bold">Partner Information</h3>
                            <input type="text" name="partnerName" value={formData.partnerName} onChange={handleChange} placeholder="Partner's Name" className="w-full p-2 my-2 border question_card_fild" />
                            <input type="date" name="partnerBirthDate" value={formData.partnerBirthDate} onChange={handleChange} className="w-full p-2 my-2 border question_card_fild" />
                            <input type="time" name="partnerBirthTime" value={formData.partnerBirthTime} onChange={handleChange} className="w-full p-2 my-2 border question_card_fild" />
                            <input type="text" name="partnerBirthPlace" value={formData.partnerBirthPlace} onChange={handleChange} placeholder="Partner's Birth Place" className="w-full p-2 my-2 border question_card_fild" />
                        </div>
                    )}
                    <button onClick={nextStep} className="mt-4 bg-red-500 hover:bg-red-600 py-2 px-4 rounded">Next</button>
                </div>
            )}

            {currentStep === 2 && (
                <div>
                    <h3 className="text-lg font-bold">Birth Information</h3>
                    <input type="time" name="birthTime" value={formData.birthTime} onChange={handleChange} className="w-full p-2 my-2 border question_card_fild" />
                    <input type="text" name="birthPlace" value={formData.birthPlace} onChange={handleChange} placeholder="Birth Place" className="w-full p-2 my-2 border question_card_fild" />
                    <select name="gender" value={formData.gender} onChange={handleChange} className="w-full p-2 my-2 border question_card_fild">
                        <option value="">Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                    <input type="text" name="occupation" value={formData.occupation} onChange={handleChange} placeholder="Occupation" className="w-full p-2 my-2 border question_card_fild" />

                    <div className="flex flex-row gap-[10px] justify-center">
                        <button onClick={prevStep} className="mt-4 bg-gray-400 text-white py-2 px-4 rounded">Back</button>
                        <button onClick={nextStep} className="mt-4 bg-red-500 hover:bg-red-600 py-2 px-4 rounded">Next</button>
                    </div>
                </div>
            )}

            {currentStep === 3 && selectedCard && selectedCard.Questions > 0 && (
                <div>
                    <h3 className="text-lg font-bold">Answer Questions</h3>
                    {formData.questions.map((question, index) => (
                        <input
                            key={index}
                            type="text"
                            name={`question_${index}`}
                            value={question}
                            onChange={handleChange}
                            placeholder={`Question ${index + 1}`}
                            className="w-full p-2 my-2 border question_card_fild"
                        />
                    ))}
                    <div className="flex flex-row gap-[10px] justify-center">
                        <button onClick={prevStep} className="mt-4 bg-gray-400 text-white py-2 px-4 rounded">Back</button>
                        <button onClick={handlePayment} className="mt-4 bg-red-500 hover:bg-red-600 py-2 px-4 rounded">Submit</button>
                    </div>
                </div>
            )}

            <ToastContainer />
        </div>
    );
};
